class ZIDXAccountRetsServerEdit implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountRetsServerEditContainer";
    }
    getMatchURL(){
        return "/account/data-feeds/rets-server-logins/edit";
    }
    render(){
        // console.log("data feeds");
    }
}